import './App.scss';
import NavBar from './Components/navbar.jsx'
import Header from './Components/header/header.jsx';
import Portfolio from './Components/portfolio/portfolio.jsx';
import Tools from './Components/tools/tools.jsx';
import PortfolioADV from './Components/portfolioADV/portfolioADV.jsx';
import Footer from './Components/footer/footer.jsx';
import AnimCursor from './Components/AnimCursor';
import Gallery from './Components/portfolioGallery/portfolioGallery'; 

function App() {


  
  return (
    <div className="App">
      <AnimCursor/>
      <Header />
      <Tools />
      <Portfolio />
      <PortfolioADV />
      <Gallery/>
      <Footer/>
      <NavBar/>
      <div id='repVideo'></div>
    </div>
  );
}

export default App;
