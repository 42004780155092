import slgicon from '../../images/slgicon.png';
import trailer from '../../images/trailer4.webm';
import close from './close.png';
import './portfolio.scss';
import AnimCursor from '../AnimCursor';
import TypeWriter from 'typewriter-effect';   

const Portfolio = () => {
    
    function toggleVideo() {
      const trailerVid = document.querySelector('.trailer');
      trailerVid.classList.toggle('active');
    };

    return(
    <section className='main'>
      <div className="titleAndButton">
      <TypeWriter onInit={(typewriter) => {typewriter.typeString("Projects").start();}}/>
      <div>
      <img src={slgicon} style={{width: "20%", maxWidth: "20%"}}></img>
      <h1>Use case | Southlights Group</h1>
      <p>Southlights Group is a DevOps service provider that delivers end-to-end automated solutions in the cloud, following a culture where development and operations teams ultimately converge to make your deployment workflows more reliable and efficient.</p><p>
      In order to carry out its digital transformation, a website was developed to provide information about the services provided by the company.</p>
</div>
<div className="button-layout">

      <button class="button" onClick={toggleVideo}>
      <div class="qube">
         <div class="front">Play</div>
         <div class="back">View preview</div>
      </div>
   </button>
   </div>
   
      
      </div>

    <div className="trailer">
    <AnimCursor/>
      <video src={trailer} loop muted autoPlay controls controlsList="nodownload" />
      <img src={close} className="close" alt="close" onClick={toggleVideo}></img>
    </div>
  </section>

);


};
export default Portfolio; 